import Container from "react-bootstrap/Container";

function BeefProjects() {
  return (
    <Container className="d-flex justify-content-center align-items-center text-center">
      <h6>
        So I am still designing this site and this page is not finished sorry
        .-.
        <br /> <br />
        But you can check my
        <a
          href="https://github.com/Beefeuteer"
          target="_blank"
          rel="noreferrer"
        >
          {"  "}
          github profile{"  "}
        </a>
        for my projects. I will update this page soon.
      </h6>
    </Container>
  );
}

export default BeefProjects;
